import { Component, Show, createSignal, onCleanup, onMount } from "solid-js";

import { influencerStore } from "../../store";
import {
  mapSearchStringData,
  mapStoreItemToLocationSearch,
  mapURLSearchParamsToStore,
} from "../../services/data-mapper";
import { fetchData } from "../../services/api/search-api-handler";
import { handleInfiniteScroll } from "../../utils/infitive-scroll";

import InfluencerList from "./influencer-list/influencer-list";
import IndustryAndTypes from "./industry-and-types/industry-and-types";
import ZipAndCountries from "./city-and-countries/city-and-countries";

export const influencerCategories = {
  INFLUENCER: "INFLUENCER",
  FREIE_JOURNALISTEN: "FREIE_JOURNALISTEN",
};

export const influencerRoutes = {
  INFLUENCER: "influencer",
  FREIE_JOURNALISTEN: "freiejournalisten",
};

import InfluencerResultToolbar from "./influencer-result-toolbar/influencer-result-toolbar";

const Influencer: Component = () => {
  let _infiniteScrollCleanup = null;
  const [resultListType, setResultListType] = createSignal(
    influencerCategories.INFLUENCER,
  );

  const { influencerState, setInfluencerState } = influencerStore;

  const initInfiniteScroll = () => {
    _infiniteScrollCleanup = handleInfiniteScroll(async () => {
      if (influencerState.offset >= influencerState.totalHits) {
        return;
      }
      setInfluencerState("offset", (offset) => offset + 10);
      const responseData = await getInfluencerDataAndSetState();
      setInfluencerState("hits", [
        ...influencerState.hits,
        ...responseData.hits,
      ]);
    });
  };

  onMount(async () => {
    mapURLSearchParamsToStore(influencerState, setInfluencerState);
    initInfiniteScroll();
    if (influencerState.genre?.length > 0) {
      handleSearch();
    }
  });

  onCleanup(async () => {
    if (_infiniteScrollCleanup != null) {
      _infiniteScrollCleanup();
    }
  });

  const getInfluencerDataAndSetState = async () => {
    const route =
      influencerState.category === influencerCategories.INFLUENCER
        ? influencerRoutes.INFLUENCER
        : influencerRoutes.FREIE_JOURNALISTEN;

    const term = `/${route.toLowerCase()}?${mapSearchStringData(
      influencerState,
    )}`;
    mapStoreItemToLocationSearch(influencerState);

    return await fetchData(term);
  };

  const handleSearch = async () => {
    if (influencerState.genre.length === 0) {
      const snackbar = document.getElementById("empty-genre");
      snackbar.dispatchEvent(new Event("snackbar:open"));
      return;
    }

    setInfluencerState("isLoading", true);
    setInfluencerState("offset", 0);
    const responseData = await getInfluencerDataAndSetState();

    setInfluencerState("isLoading", false);
    if (responseData.status === 403) {
      const dialog = document.getElementById("not-logged-in-dialog");

      dialog.dispatchEvent(new Event("not-logged-in:dialog:open"));
    }
    setInfluencerState("hits", responseData.hits);
    setInfluencerState("totalHits", responseData.totalHits);

    setResultListType(influencerState.category);
  };

  return (
    <>
      <section class="section-append ">
        <div class="action-area">
          <div class="mdc-layout-grid">
            <div class="mdc-layout-grid__inner">
              <IndustryAndTypes />
              <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">
                <ZipAndCountries handleSearch={handleSearch} />
              </div>
              <div class="mdc-layout-grid__inner marginTop25">
                <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-2">
                  <tp-button onClick={handleSearch}>SUCHEN</tp-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Show when={influencerState.isLoading}>
          <tp-circular-progress />
        </Show>

        <InfluencerResultToolbar handleSearch={handleSearch} />

        <Show
          when={[
            influencerCategories.INFLUENCER,
            influencerCategories.FREIE_JOURNALISTEN,
          ].includes(resultListType())}
        >
          <InfluencerList
            hits={influencerState.hits}
            totalHits={influencerState.totalHits}
          />
        </Show>
      </section>
      <tp-snackbar id="empty-genre">
        Bitte wählen Sie eine Branche aus!
      </tp-snackbar>
    </>
  );
};

export default Influencer;
