// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
// recommended to use a sentinel:
// - https://dev.to/vikas2426/building-an-infinite-scroll-component-with-intersection-observer-17p1
// - https://web.dev/articles/intersectionobserver
// some more hints: https://stackoverflow.com/questions/61951380/intersection-observer-fails-sometimes-when-i-scroll-fast
// another implementation: https://primitives.solidjs.community/package/pagination/#createinfinitescroll

export const handleInfiniteScroll = (loadNextPage: () => Promise<void>) => {
  const sentinel = document.querySelector("tp-footer");
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5, // Trigger when the sentinel is 50% visible
  };

  const handleIntersection = async (entries) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        await loadNextPage();
      }
    }
  };

  const observer = new IntersectionObserver(handleIntersection, options);
  observer.observe(sentinel);
  return () => {
    observer.unobserve(sentinel);
    observer.disconnect();
  };
};
