import { produce } from "solid-js/store";
import {
  COUNTRYCODE_FROM_GERMAN,
  COUNTRYCODE_TO_GERMAN,
  MODES_API,
} from "../constants";
import { transformSearchInput } from "../utils/search-term-handler";
import { toIsoDate } from "../utils/date-utils";
import { influencerCategories } from "../components/influencer/influencer.route";
import { useLocation } from "@solidjs/router";

export const mapSearchStringData = (searchData) => {
  const {
    searchTerm,
    excludedTerms,
    zip,
    publishDateEnd,
    publishDateStart,
    from,
    to,
    countries,
    mode,
    mediaGenre,
    offset,
    sort,
    selectionExclusions,
    genre,
    socialMediaType,
  } = searchData;

  const _searchTerm = searchTerm && transformSearchInput(searchTerm);
  const _excludedTerms = excludedTerms && transformSearchInput(excludedTerms);
  const _zip = zip && transformSearchInput(zip);

  const mappedData = {
    ...(_searchTerm && { includeTerms: _searchTerm }),
    ...(MODES_API[mode] && { mode: MODES_API[mode] }),
    ...(_excludedTerms && { excludeTerms: _excludedTerms }),
    ...(zip && { plzOrt: _zip }),
    ...(publishDateEnd && { publishDateEnd }),
    ...(publishDateStart && { publishDateStart }),
    ...(from && { von: from }),
    ...(to && { bis: to }),
    ...(sort && { sort }),
    ...(socialMediaType && { socialMediaType }),
  };

  let mapToSearchParams = new URLSearchParams(mappedData).toString();

  if (countries.length > 0) {
    countries.forEach((country) => {
      return (mapToSearchParams += `&land=${country}`);
    });
  }

  if (mediaGenre && mediaGenre.length > 0) {
    mediaGenre.forEach(
      (genre) => (mapToSearchParams += `&mediengattungen=${genre}`),
    );
  }

  if (genre && genre.length > 0) {
    //eslint-disable-next-line @typescript-eslint/no-unused-expressions
    searchData.category === influencerCategories.FREIE_JOURNALISTEN
      ? genre.forEach(
          (_genre) => (mapToSearchParams += `&themenbereich=${_genre}`),
        )
      : genre.forEach((_genre) => (mapToSearchParams += `&branche=${_genre}`));
  }

  if (selectionExclusions.length > 0) {
    selectionExclusions.forEach(
      (exclusion) => (mapToSearchParams += `&selectionExclusions=${exclusion}`),
    );
  }

  if (offset !== null && offset !== undefined) {
    mapToSearchParams += `&offset=${offset}`;
  }

  return `${mapToSearchParams}`;
};

export const mapStoreItemToLocationSearch = (searchData) => {
  const mappedData = mapStoreItemToBackendModel(searchData);

  const countries = (mappedData.land && [...mappedData.land]) || [];
  const mediaGenres =
    (mappedData.mediengattung && [...mappedData.mediengattung]) || [];

  // URLSearchParams maps arrays into on searchparam
  // land=DE%2CAT
  // but it should be
  // land=DE&land=AT
  delete mappedData.land;
  delete mappedData.mediengattung;
  let mapToSearchParams = new URLSearchParams(mappedData).toString();

  if (countries.length > 0) {
    countries.forEach(
      (country) =>
        (mapToSearchParams += `&land=${COUNTRYCODE_TO_GERMAN[country]}`),
    );
  }

  if (mediaGenres.length > 0) {
    mediaGenres.forEach(
      (genre) => (mapToSearchParams += `&mediengattungen=${genre}`),
    );
  }

  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${mapToSearchParams}`;

  window.history.pushState({ path: newurl }, "", newurl);
};

export const mapStoreItemToBackendModel = (storeData) => {
  const {
    searchTerm,
    excludedTerms,
    zip,
    publishDateEnd,
    publishDateStart,
    from,
    to,
    mode,
    countries,
    mediaGenre,
    sort,
    socialMediaType,
    category,
    genre,
  } = storeData;

  const mappedData = {
    ...(searchTerm && { suchbegriffe: `${searchTerm}` }),
    ...(mode && { suchfelder: `${mode}` }),
    ...(excludedTerms && { auszuschliessendeBegriffe: `${excludedTerms}` }),
    ...(excludedTerms && { auszuschliessendeBegriffe: `${excludedTerms}` }),
    ...(zip && { plzOrt: zip }),
    ...(publishDateEnd && { erscheinungsterminBis: publishDateEnd }),
    ...(publishDateStart && { erscheinungsterminAb: publishDateStart }),
    ...(from && { from }),
    ...(to && { to }),
    ...(sort && { sort }),
    ...(category && { kategorie: category }),
    ...(countries && countries.length > 0 && { land: [...countries] }),
    ...(mediaGenre &&
      mediaGenre.length > 0 && { mediengattung: [...mediaGenre] }),
    ...(genre &&
      genre.length > 0 &&
      (storeData.category === influencerCategories.FREIE_JOURNALISTEN
        ? { themenbereich: [...genre] }
        : { branche: [...genre] })),
    ...(socialMediaType && { socialMediaType }),
  };

  return mappedData;
};

export const mapCountriesToGerman = (countries) => {
  return countries.map((country) => COUNTRYCODE_TO_GERMAN[country]);
};

export const mapURLSearchParamsToStore = (store, setStore) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  for (const param of queryParams.keys()) {
    const item = queryParams.get(param);

    switch (param) {
      case "suchbegriffe":
        setStore("searchTerm", item);
        break;
      case "suchfelder":
        setStore("mode", item);
        break;
      case "erscheinungsterminAb":
        setStore("publishDateStart", item);
        break;
      case "erscheinungsterminBis":
        setStore("publishDateEnd", item);
        break;
      case "auszuschliessendeBegriffe":
        setStore("excludedTerms", item);
        break;
      case "plzOrt":
        setStore("zip", item);
        break;

      case "branche":
      case "themenbereich":
        setStore("genre", item.split(","));
        break;

      case "socialMediaType":
        setStore("socialMediaType", item);
        break;
      case "land":
        queryParams.getAll(param).forEach((country) => {
          const mappedCountry = COUNTRYCODE_FROM_GERMAN[country];
          if (!store.countries.includes(mappedCountry)) {
            setStore(produce((store) => store.countries.push(mappedCountry)));
          }
        });
        break;

      case "mediengattungen":
        queryParams.getAll(param).forEach((genre) => {
          if (!store.mediaGenre.includes(genre)) {
            setStore(produce((store) => store.mediaGenre.push(genre)));
          }
        });
        break;

      case "kategorie":
        setStore("category", item);
        break;

      default:
        break;
    }
  }
};

export const mapItemsToSearchLocation = (search) => {
  search.suchoptionen = {
    ...search.suchoptionen,

    ...(search.suchoptionen.erscheinungsterminAb && {
      erscheinungsterminAb: toIsoDate(search.suchoptionen.erscheinungsterminAb),
    }),
    ...(search.suchoptionen.erscheinungsterminBis && {
      erscheinungsterminBis: toIsoDate(
        search.suchoptionen.erscheinungsterminBis,
      ),
    }),
  };

  if (search.suchoptionen.land?.length === 0) {
    delete search.suchoptionen.land;
  }

  // edgecase
  let medienGattungSearchParams = "";
  if (search.suchoptionen.mediengattung) {
    const medienGattungTmp = [...search.suchoptionen.mediengattung];
    delete search.suchoptionen.mediengattung;

    medienGattungSearchParams =
      "&mediengattungen=" + medienGattungTmp.join("&mediengattungen=");
  }

  const searchParams = new URLSearchParams(search.suchoptionen)
    .toString()
    .replace("%C3%96sterreich", "AT")
    .replace("Schweiz", "CH")
    .replace("Deutschland", "DE");

  return `${window.location.protocol}//${window.location.host}/themen?${searchParams}${medienGattungSearchParams}`;
};
